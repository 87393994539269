import './App.css';
import { stageStore } from './stores/stageStore';
import Login from './containers/Login';
import MainPage from "./containers/MainPage";
import { Box } from '@mui/system';

function App() {
  const { user } = stageStore();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      { user.id === null ? <Login/> : <MainPage/> }
    </Box>
  );
}

export default App;
