import * as React from 'react';
import { stageStore } from '../stores/stageStore';
import Today from "./Today";
import {Games} from "@mui/icons-material";
import TodayVideo from "./TodayVideo";

export default function Content() {
  const { ui } = stageStore();
  const selectPage = () => {
    switch (ui.page) {
      case "Today":
        return <Today/>;
      case "TodayVideo":
        return <TodayVideo/>;
      case "Games":
        return <Games/>;
      default:
        return null;
    }
  }

  return (
    <div>
      { selectPage() }
    </div>
  )
}