import {stageStore} from "../stores/stageStore";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ReactPlayer from 'react-player'
import VideoFile from "./VideoFile";

export default function TodayVideo() {
  const {ui, today} = stageStore();
  const messageItem = today[ui.messageId].messageItems[ui.messageItemId];

  return (
    <React.Fragment>
      <Card
        variant="outlined"
        sx={{maxWidth: 600}}
        className={'today-video-card'}
      >
        <CardHeader
          title={messageItem.stage_title}
        />
        {messageItem.vimeoLink ?
          (<ReactPlayer
              className='react-player'
              width='100%'
              controls={true}
              url={messageItem.vimeoLink}
            />
          ) : (
            <video
            controls
            style={{width: '100%'}}
            className={'show-big-video'}
            src={ VideoFile(messageItem.fileId) }
            />
          )
        }
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {messageItem.stage_description ? messageItem.stage_description : 'Ei kuvaustekstiä, leikitään oletuksella.'}
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}