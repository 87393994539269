import axios from "axios";
import PropTypes from "prop-types";

function VideoFile(props) {
  const { fileId } = props;

  let videoUrl = '';
  axios.get(
    'https://staging.savelsirkku.fi/api/file/'+fileId + '?noRedirect=true',
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      }
    }
  ).then((response) => {
    console.log(response.data.url);
      videoUrl = response.data.url;
    }
  ).catch( () => {
    console.log('Error');
  })

  return videoUrl;
}

VideoFile.prototypes = {
  fileId: PropTypes.func.isRequired,
}
export default VideoFile;