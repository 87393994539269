import axios from "axios";
import { stageStore } from '../stores/stageStore';
import PropTypes from "prop-types";
function getToday(props) {
  const { jwt } = props;

  axios.post(
    'https://staging.savelsirkku.fi/api/get-stage-messages',
    {},
    {
      headers: {
        "Authorization" : `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
    }
  )
    .then((response) => {
      stageStore.setState({today:
        response.data
      })
      }
    )
}

getToday.prototypes = {
  jwt: PropTypes.func.isRequired,
}

export default getToday;