import {useRef} from "react";
import axios from "axios";
import { stageStore } from '../stores/stageStore';
import getToday from "../Actions/getToday";
import getGames from "../Actions/getGames";
//import getVimeo from "../Actions/getVimeo";
export default function Login() {
  const username = useRef();
  const password = useRef();
  const sendLogin = async () => {
    axios.post(
      `https://staging.savelsirkku.fi/api/login`,
      {
        username: username.current.value,
        password: password.current.value,
        jwt: true
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      ).then((response) => {
        const loginToken = response.data.jwt;

        axios.get(
          'https://staging.savelsirkku.fi/api/',
    {
            headers: {
              "Authorization" : `Bearer ${loginToken}`,
              'Content-Type': 'application/json',
            }
          }).then((response) => {
          stageStore.setState({user: {
              id: response.data.user.id,
              name: response.data.user.name,
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              clientName: response.data.user.clientName,
              organization: response.data.user.organization,
              loginToken: loginToken,
            }
          });
          getToday({jwt: loginToken});
          getGames();
          //getVimeo();
        });
    });
  };

return (
  <div className={'loginForm'}>
    <form>
      <input type={'text'} name={'username'} ref={username} defaultValue={'NäyttämöDemo'}/>
      <input type={'password'} name={'password'} ref={password} defaultValue={'!RIP/!:P*T2XxkMDcM5pY/2Uk4QN9n2'}/>
      <button  type={"button"} onClick={sendLogin}>Login</button>
    </form>
  </div>
);
}