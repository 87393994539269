import * as React from "react";
import {stageStore} from "../stores/stageStore";
import {Card, CardContent, CardHeader, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
export default function Today() {
  const { today } = stageStore();

  return (
    <React.Fragment>
      {
        today.map((message, index) => {
          return(
            message.messageItems.map((messageItem, itemIndex) => {
              if(messageItem.type !== 'MediaFile') {
                return null;
              }

              return(
                <Card
                  variant="outlined"
                  sx={{ maxWidth: 345 }}
                  className={'today-video-card'}
                  onClick={() => stageStore.setState({ui: {page: 'TodayVideo', messageId: index, messageItemId: itemIndex}})}
                  key={ index + '_' + itemIndex}
                >
                  <CardHeader
                    title={messageItem.stage_title}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image="/images/video_sample.png"
                    alt="{messageItem.stage_title}"></CardMedia>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    {messageItem.stage_description ? messageItem.stage_description : 'Ei kuvaustekstiä, leikitään oletuksella.'}
                    </Typography>
                  </CardContent>
                </Card>
              )
            })
          )
        })
      }
    </React.Fragment>
  );
}