import {create} from "zustand";
import {devtools} from "zustand/middleware";

export const stageStore = create(
  devtools(
    (set) => ({
      user: {
        id: null,
        name: null,
        firstName: null,
        lastName: null,
        clientName: null,
        organization: null,
        email: null,
        timeZone: null,
        config: null,
        loginToken: null,
      },
      ui: {
        page: null,
        messageId: null,
        messageItemId: null
      },
      today: {},
      Games: {}
    }),
    {name: 'sentina-stage-store'}
  )
);
