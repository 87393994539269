import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { stageStore } from '../stores/stageStore';
const item = {
  py: '2px',
  px: 3,
  height: '100px',
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function LeftMenu(props) {
  const {...other} = props;


  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{...item, ...itemCategory, fontSize: 22, color: '#fff', height: '200px'}}>
          Näyttämö
        </ListItem>
        <ListItem
          sx={{...item, ...itemCategory}}
          onClick={() => stageStore.setState({ui: {page: 'Today'}})}
        >Tänään</ListItem>
        <ListItem
          sx={{...item, ...itemCategory}}
          onClick={() => stageStore.setState({ui: {page: 'Games'}})}
        >Pelit</ListItem>
        <ListItem sx={{...item, ...itemCategory}}>Liikunta</ListItem>
      </List>
    </Drawer>
  );
}