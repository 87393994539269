import axios from "axios";
import { stageStore } from '../stores/stageStore';
export default function getGames() {
  axios.get(
    'https://bfapi.savelsirkku.fi/Games',
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
    .then((response) => {
      stageStore.setState({games:
        response.data
      })
      }
    )
}